body {
    color: #434343;
    font-family: "Montserrat", Sans-serif;
    background-color: #f5f5f5;
    text-align: center;
}

@media (max-width: 768px) {
    .app-logo {
        max-width: 200px;
    }



    .logos-container {
        flex-direction: column;
    }

    .logos-divider {
        width: 70% !important;
        height: 1px !important;
        margin: 2rem 0 !important;
    }
}

.navbar {
    height: 70px;
    border-bottom: 1px solid #e9e7e7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding: 0 3rem;
}

.navbar-logo {
    width: 40px;
}

.navbar li {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
.welcome-text {
font-size: 3rem;
margin: 0;
}

.description-text {
    font-size: 1rem;
    margin-bottom: 5rem;
}
.app-footer {
    width: 100vw;
    height: 70px;
    border-top: 1px solid #e9e7e7;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.app-logo {
    max-width: 300px;
}

.app-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 240px);
}

.app-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
}

.nav-link {
    text-decoration: none;
    color: #434343;
    transition: all 300ms;
}

.nav-link:hover {
    color: #546d5e;
}

.logos-container {
    display: flex;
    align-items: center;
}

.logos-divider {
    margin: 0 2rem;
    height: 70px;
    width: 2px;
    background-color: #d8d7d7;
}